<template>
  <div class="private-page">
    <div class="private-page__content">
      <div class="private-page__content_header">
        <h2 class="title">{{ room.title }}</h2>
      </div>
      <section v-for="item in room.sections">
        <div class="private-page__content_body">
          <h4>{{ item.title }}</h4>
          <h6>{{ item.artist }}</h6>
          <p>{{ item.description }}</p>
        </div>
        <Carousel :images="item.carousel"></Carousel>
      </section>
    </div>
  </div>
</template>
<script>
import Carousel from "@/components/Carousel.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { Carousel },
  async created() {
    await this.apiGetPublicViewingRoom(this.$route.params.slug);
  },
  computed: {
    ...mapGetters("privateViewingRoom", {
      room: "getViewingRoom",
    }),
  },
  methods: {
    ...mapActions("privateViewingRoom", ["apiGetPublicViewingRoom"]),
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 18px;
  line-height: 1.55;
  @media screen and (max-width: $xs) {
    font-size: 16px;
  }
}

.private-page {
  display: flex;
  padding: 0 0 50px;
  &__content {
    position: relative;
    width: 100%;
    margin-top: 145px;
    padding: 0rem 0 7.4rem;
    z-index: 2;
    &_header,
    &_body {
      max-width: 845px;
      padding: 15px;
      margin: 0 auto;
    }
    &_header {
      h2 {
        font-size: 4rem;
        line-height: 1.2;
        font-weight: 500;
        @media screen and (max-width: $xs) {
          font-size: 3rem;
        }
      }
    }
    &_body {
      padding-top: 25px;
      padding-bottom: 25px;
      margin-top: 40px;
      margin-bottom: 28px;
      h4 {
        font-size: 3.2rem;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 10px;
        @media screen and (max-width: $xs) {
          font-size: 2.6rem;
        }
      }
      h6 {
        font-size: 2.4rem;
        line-height: 1.2;
        font-weight: 500;
        margin-bottom: 10px;
        @media screen and (max-width: $xs) {
          font-size: 2rem;
        }
      }
      p {
        font-size: 1.8rem;
        line-height: 1.55;
        font-weight: 300;
        margin-bottom: 10px;
      }
    }
  }
}
</style>

